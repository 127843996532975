<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Ver Incidecias ">
          <b-card-text>Tab contents 2</b-card-text>
        </b-tab>
        <b-tab title="Alta de incidencia " active>
          <TabInfo />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import TabInfo from "@/modules/incidents/components/TabGeneralDetail"

import {mapState} from 'vuex'
export default {
  async mounted() {},
  components: {
    TabInfo,
  },

  data() {},
  computed: {},
  methods: {
    ...mapState('incidents',['incident']),
  },
};
</script>
