<template>
  <div>
    <b-row>
      <b-col md="7">
        <b-card no-body>
          <b-card-body>
            <div class="text-center mb-1">
              <h4>Customer Data</h4>
              <b>Name:</b> {{ selectedOrderOperation.customer.name }} <br />
              <b>Email:</b> {{ selectedOrderOperation.customer.email }}<br />
              <b>Phone:</b> {{ selectedOrderOperation.customer.phone }}<br />

              <b>Payment form:</b> {{ selectedOrderOperation.paymentForm
              }}<br />
              <b>Hotel:</b> {{ selectedOrderOperation.hotel }}<br />
              <b  v-if="selectedOrderOperation.reference != ''">Reference:</b> {{ selectedOrderOperation.reference }}<br />
              <div v-if="selectedOrderOperation.note != ''">
                <b>Note</b>
                {{ selectedOrderOperation.note }}
              </div>
              <br />
            </div>

            <ValidationObserver ref="incidencias" v-slot="{ invalid }">
              <b-row>
                <b-col>
                  <span>Título incidencia</span>
                  <ValidationProvider rules="required" name="Título">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-input
                        placeholder="Título incidencia"
                        v-model="incident.incidentName"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
                <b-col>
                  <span>No. POS</span>
                  <ValidationProvider rules="" name="No. POS">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-input
                        v-model="selectedOrderOperation.order"
                        placeholder="No. POS"
                        :disabled="true"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="6">
                  <span>Tipo de Queja</span>
                  <ValidationProvider rules="required" name="Categoría">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-input
                        v-model="selectedOrderOperation.categoryname"
                        placeholder="Ingrese Categoría"
                        :state="errors[0] ? false : valid ? true : null"
                        :disabled="true"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <span>Proveedor</span>
                  <ValidationProvider rules="required" name="proveedor">
                    <b-form-group slot-scope="{ valid, errors }">
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="selectedOrderOperation.supplier"
                        :options="suppliers"
                        :disabled="true"
                      >
                      </b-form-select>
                      <b-form-invalid-feedback
                        >{{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="6">
                  <span>Quien atendio</span>
                  <ValidationProvider rules="required" name="Informador">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-input
                        type="text"
                        v-model="incident.createBy"
                        placeholder="Quien atendio"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="6">
                  <span>Fecha de queja </span>
                  <ValidationProvider rules="required" name="Asignada a">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-input
                        type="date"
                        v-model="incident.crateDate"
                        :value="incident.crateDate"
                        placeholder="Ingrese Asignada a"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="4">
                  <span>Hora</span>
                  <ValidationProvider rules="required" name="Asignada a">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-input
                        type="time"
                        v-model="incident.crateHour"
                        placeholder="Ingrese Asignada a"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4">
                  <span>Asignada a</span>
                  <ValidationProvider rules="required" name="Asignada a">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-input
                        type="text"
                        v-model="incident.assigned"
                        placeholder="Ingrese Asignada a"
                        :state="errors[0] ? false : valid ? true : null"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4">
                  <span>Estatus de queja </span>
                  <ValidationProvider rules="required" name="Estatus de queja ">
                    <b-input-group slot-scope="{ valid, errors }">
                      <b-form-select
                        type="text"
                        v-model="incident.statusIncident"
                        placeholder="Estatus de queja "
                        :state="errors[0] ? false : valid ? true : null"
                        :options="status"
                      >
                        ></b-form-select
                      >
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <span>Queja</span>
                  <ValidationProvider rules="max:1500" name="Resumen">
                        <b-form-group
              slot-scope="{ valid, errors }"
              label="Nota Interna( max: 1500)"
            >
                      <b-input-group>
                        <b-form-textarea
                          v-model="incident.summary"
                          rows="2"
                          max-rows="3"
                          :state="errors[0] ? false : valid ? true : null"
                        >
                        </b-form-textarea>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                           <span class="text-muted">Carácteres restantes {{maxTextTarea - incident.summary.length}} </span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <span>Como se Resolvió</span>
                  <ValidationProvider rules="max:1500" name="Descripción">
                                 <b-form-group
              slot-scope="{ valid, errors }"
              label="Nota Interna( max: 1500)"
            >
                      <b-input-group>
                        <b-form-textarea
                          rows="2"
                          v-model="incident.description"
                          max-rows="3"
                          :state="errors[0] ? false : valid ? true : null"
                        >
                        </b-form-textarea>
                    
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                        
                      </b-input-group>
                          <span class="text-muted">Carácteres restantes {{maxTextTarea - incident.description.length}} </span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="12">
                  <ValidationProvider name="Evidencias">
                    <b-form-group
                      label="Evidencias"
                      slot-scope="{ valid, errors }"
                    >
                      <b-form-file
                        v-model="file"
                        @input="setImgFile(file)"
                        placeholder="Adjunte una imagen como evidencia..."
                        drop-placeholder="Drop file here..."
                        :state="errors[0] ? false : valid ? true : null"
                        browse-text="Buscar imagen"
                      />
                    </b-form-group>
                  </ValidationProvider>

                  <b-card-text class="my-1">
                    <table
                      v-if="incident.file.name != '' && incident.file != ''"
                      class="table table-bordered table-sm mb-1"
                    >
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Nombre</th>
                          <th scope="col">Borrar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              :href="attrLink + '' + incident.file.name"
                              download
                              target="_blank"
                              >{{ incident.file.nameShort  ? incident.file.nameShort : incident.file.name }}</a
                            >
                          </td>
                          <td>
                            <a
                              :href="attrLink + '/' + incident.file.name"
                              download
                              target="_blank"
                            >
                              <b-button
                                size="sm"
                                variant="success"
                                class="mr-1"
                              >
                                <span class="pdfIconLoad"> 📄 </span>
                              </b-button>
                            </a>

                            <b-button
                              size="sm"
                              variant="danger"
                              @click="deleteFileIncident()"
                            >
                              <b-icon icon="trash-fill"></b-icon>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                   <div v-if="incident.id != ''" class="float-left">
                    <a
                      :href="
                        attrLink +
                        '/Transactions/indicentensreport/' +
                       incident.id+'/'
                      "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b-button
                        variant="primary"
                        :disabled="invalid"
                      
                      >
                     <b-icon-file-earmark-ruled-fill></b-icon-file-earmark-ruled-fill>
                        Generear PDF
                      </b-button>
                    </a>
                  </div>
                  <div v-if="incident.id == ''" class="float-right">
                    <b-button
                      variant="primary"
                      :disabled="invalid"
                      @click="sendIncident(incident, selectedOrderOperation.id)"
                    >
                      <b-icon-check-square-fill></b-icon-check-square-fill>
                      Guardar
                    </b-button>
                  </div>
                  <div v-else class="float-right">
                    <b-button
                      variant="primary"
                      :disabled="invalid"
                      @click="update(incident, incident.id)"
                    >
                      <b-icon-check-square-fill></b-icon-check-square-fill>
                      Actualizar
                    </b-button>
                  </div>
                 
                </b-col>
              </b-row>
            </ValidationObserver>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="5">
        <b-card no-body>
          <b-card-body>
            <h3 class="text-center mt-1">Segimiento de incidencias</h3>

            <ValidationObserver ref="incidencias">
              <div class="description">
                <div
                  v-for="(items, index) in summary"
                  :key="index"
                  class="message-box-holder"
                >
                  <div :class="items.class">
                    <span class="titleDescription">
                      {{ items.username }} {{ items.createdate }}
                    </span>

                    <div class="mb-1">{{ items.summary }}</div>
                    <span
                      v-if="items.files.name != ''"
                      class="titleDescriptionFooter align-items-left"
                    >
                      <a
                        :href="attrLink + '/' + items.files.name"
                        download
                        target="_blank"
                      >
                        <span class="pdfIcon"> 📄 </span>
                      </a>
                    </span>
                    <span class="titleDescriptionFooter align-items-right">
                      {{ items.time }}
                    </span>
                  </div>
                </div>
              </div>
              <b-row class="mt-1">
                <b-col>
                  <span>Comentarios</span>
                  <ValidationProvider rules="max:255" name="comments">
                    <b-form-group slot-scope="{ valid, errors }">
                      <b-input-group>
                        <b-form-textarea
                          v-model="comments"
                          rows="2"
                          max-rows="3"
                          :state="errors[0] ? false : valid ? true : null"
                        >
                        </b-form-textarea>
                        <b-form-invalid-feedback>
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col md="12">
                  <ValidationProvider name="CommentFile">
                    <b-form-group
                      label="Evidencias"
                      slot-scope="{ valid, errors }"
                    >
                      <b-form-file
                        v-model="commentsFile"
                        @input="setCommentFile(commentsFile)"
                        placeholder="Adjunte una imagen como evidencia..."
                        drop-placeholder="Drop file here..."
                        :state="errors[0] ? false : valid ? true : null"
                        browse-text="Buscar imagen"
                      />
                    </b-form-group>
                  </ValidationProvider>

                  <b-card-text class="my-1">
                    <table
                      v-if="commentsFile != null"
                      class="table table-bordered table-sm mb-1"
                    >
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Nombre</th>
                          <th scope="col">Borrar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ commentsFile.name }}</td>
                          <td>
                            <b-button
                              size="sm"
                              variant="danger"
                              @click="deleteFileComment()"
                            >
                              <b-icon icon="trash-fill"></b-icon>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <div class="float-right">
                    <b-button
                      variant="primary"
                      @click="createSummary(selectedOrderOperation.id)"
                      :disabled="incident.id == ''"
                    >
                      <b-icon-check-square-fill></b-icon-check-square-fill>
                      Guardar
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </ValidationObserver>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { mapActions, mapState, mapMutations } from "vuex"
import { showAlertMessage } from '@/helpers/helpers'   


export default {
  async mounted() {
    await this.loadInfo();
  },
  computed: {
    ...mapState("operation", [
      "selectedOrderOperation",
      "suppliers",
      "operators",
    ]),
    ...mapState("auth", ["user"]),
    ...mapState("incidents", ["incident", "status", "summary"]),
  },
  data() {
    return {
      file: null,
      fileList: [],
      commentsFile: null,
      comments: "",
      attrLink: process.env.VUE_APP_IMG_SRC_API,
      type: "",
      max: 1500,                                                                  
      maxTextTarea: 1500,    
    };
  },
  methods: {
    ...mapActions("operation", [
      "fetchOperatorsBySupplier",
      "updateOrderOperation",
      "updateSupplierOperator",
    ]),
    ...mapActions("incidents", [
      "fetchStatusIncidents",
      "createIncident",
      "fetchIncidents",
      "incidentsdescription",
      "fetchInincIdentsDescription",
      "incidentdesupdate",
    ]),

    ...mapMutations("incidents", [
      "deleteFile",
      "addFileIncident",
      "setIncidentClear",
      "setIncidentsdescription",
    ]),
    ...mapMutations("operation", [
      "setSupplierOrderOperation",
      "unsetOperators",
      "setOperatorOrderOperation",
    ]),
    async setSupplierAndGetOperators(supplier) {
      if (supplier) {
        await this.setSupplierOrderOperation(supplier);
        await this.fetchOperatorsBySupplier(supplier);
      } else {
        this.unsetOperators();
      }
    },
    setOperator(operator) {
      if (operator) {
        this.setOperatorOrderOperation(operator);
      }
    },
    setImgFile(file) {
      if (file) {
        this.addFileIncident(file);
      } else {
        const text = `😪😪 Tiene que adjuntar un archivo de tipo imagen`;
        showAlertMessage(
          "No hay imagen",
          "InfoIcon",
          text,
          "danger",
          4000,
          "bottom-right"
        );
      }
    },
    setCommentFile(commentsFile) {
      if (commentsFile) {
        this.addcommentsFile(commentsFile);
      } else {
        const text = `😪😪 Tiene que adjuntar un archivo de tipo imagen`;
        showAlertMessage(
          "No hay imagen",
          "InfoIcon",
          text,
          "danger",
          4000,
          "bottom-right"
        );
      }
    },
    deleteFileIncident() {
      this.incident.file = "";
      this.file = "";
    },
    deleteFileComment() {
      this.commentsFile = null;
    },
    sendIncident(incident, id) {
      this.incident.pos = id;
      this.createIncident(incident);
      this.loadInfo();
      this.getOperations();
    },
    async createSummary(id) {
      const payload = {
        id: id,
        userId: this.user.idUser,
        summary: this.comments,
        commentsFile: this.commentsFile,
      };
      this.comments = "";
      await this.incidentsdescription(payload);
      await this.fetchInincIdentsDescription(this.incident.id)
    },

    update(incident, id) {
      this.incident.pos = id;
      this.incidentdesupdate(incident);
      this.getOperations();
    },

    async loadInfo() {
      await this.setIncidentClear();
      await this.fetchStatusIncidents();
      await this.fetchIncidents(this.selectedOrderOperation.id)
      await this.fetchInincIdentsDescription(this.incident.id)
    },
    async getOperations() {
      await this.$emit("get-operations-after-save")
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1000px !important;
  }
}

.description {
  background: aliceblue;
  max-height: 671px;
  overflow: auto;
  padding: 10px;
}
.table-bordered td {
  word-break: break-all;
}

.message-box {
  padding: 6px 10px;
  border-radius: 6px 0 6px 0;
  position: relative;
  background: rgba(100, 170, 0, 0.1);
  border: 2px solid rgba(100, 170, 0, 0.1);
  color: #6c6c6c;
  font-size: 12px;
}

.message-box:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  border-top: 10px solid rgba(100, 170, 0, 0.2);
  border-right: none;
  bottom: -22px;
  right: 10px;
}

.message-partner {
  background: rgba(0, 114, 135, 0.1);
  border: 2px solid rgba(0, 114, 135, 0.1);
  align-self: flex-start;
}

.message-partner:after {
  right: auto;
  bottom: auto;
  top: -22px;
  left: 9px;
  border: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 114, 135, 0.2);
  border-left: none;
}

.message-box-holder {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.message-sender {
  font-size: 12px;
  margin: 0 0 15px;
  color: #30649c;
  align-self: flex-start;
}

span.titleDescription {
  font-size: 11px;
  display: block;
  text-align: end;
  margin: 0 0 10px 0;
}
.message-box img {
  object-fit: cover;
  width: 130px;
}
.pdfIcon {
  font-size: 15px;
}
.pdfIcon span {
  font-size: 9px;
  font-weight: 600;
  word-break: break-all;
  width: 161px;
  display: inline-block;
}

span.titleDescriptionFooter {
  display: inline-block;
  width: 50%;
}

span.titleDescriptionFooter.align-items-right {
  text-align: right;
  float: right;
}

span.titleDescriptionFooter.align-items-left {
  float: left;
}
span.pdfIconLoad {
  font-size: 17px;
  margin: 0 0 0 0px;
}
</style>
