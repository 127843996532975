var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"7"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('div',{staticClass:"text-center mb-1"},[_c('h4',[_vm._v("Customer Data")]),_c('b',[_vm._v("Name:")]),_vm._v(" "+_vm._s(_vm.selectedOrderOperation.customer.name)+" "),_c('br'),_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.selectedOrderOperation.customer.email)),_c('br'),_c('b',[_vm._v("Phone:")]),_vm._v(" "+_vm._s(_vm.selectedOrderOperation.customer.phone)),_c('br'),_c('b',[_vm._v("Payment form:")]),_vm._v(" "+_vm._s(_vm.selectedOrderOperation.paymentForm)),_c('br'),_c('b',[_vm._v("Hotel:")]),_vm._v(" "+_vm._s(_vm.selectedOrderOperation.hotel)),_c('br'),(_vm.selectedOrderOperation.reference != '')?_c('b',[_vm._v("Reference:")]):_vm._e(),_vm._v(" "+_vm._s(_vm.selectedOrderOperation.reference)),_c('br'),(_vm.selectedOrderOperation.note != '')?_c('div',[_c('b',[_vm._v("Note")]),_vm._v(" "+_vm._s(_vm.selectedOrderOperation.note)+" ")]):_vm._e(),_c('br')]),_c('ValidationObserver',{ref:"incidencias",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',[_c('b-col',[_c('span',[_vm._v("Título incidencia")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Título"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-input-group',{},[_c('b-form-input',{attrs:{"placeholder":"Título incidencia","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.incident.incidentName),callback:function ($$v) {_vm.$set(_vm.incident, "incidentName", $$v)},expression:"incident.incidentName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',[_c('span',[_vm._v("No. POS")]),_c('ValidationProvider',{attrs:{"rules":"","name":"No. POS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-input-group',{},[_c('b-form-input',{attrs:{"placeholder":"No. POS","disabled":true,"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.selectedOrderOperation.order),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation, "order", $$v)},expression:"selectedOrderOperation.order"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('span',[_vm._v("Tipo de Queja")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Categoría"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-input-group',{},[_c('b-form-input',{attrs:{"placeholder":"Ingrese Categoría","state":errors[0] ? false : valid ? true : null,"disabled":true},model:{value:(_vm.selectedOrderOperation.categoryname),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation, "categoryname", $$v)},expression:"selectedOrderOperation.categoryname"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('span',[_vm._v("Proveedor")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"proveedor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('b-form-select',{attrs:{"state":errors[0] ? false : valid ? true : null,"options":_vm.suppliers,"disabled":true},model:{value:(_vm.selectedOrderOperation.supplier),callback:function ($$v) {_vm.$set(_vm.selectedOrderOperation, "supplier", $$v)},expression:"selectedOrderOperation.supplier"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('span',[_vm._v("Quien atendio")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Informador"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-input-group',{},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Quien atendio","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.incident.createBy),callback:function ($$v) {_vm.$set(_vm.incident, "createBy", $$v)},expression:"incident.createBy"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('span',[_vm._v("Fecha de queja ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Asignada a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-input-group',{},[_c('b-form-input',{attrs:{"type":"date","value":_vm.incident.crateDate,"placeholder":"Ingrese Asignada a","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.incident.crateDate),callback:function ($$v) {_vm.$set(_vm.incident, "crateDate", $$v)},expression:"incident.crateDate"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"4"}},[_c('span',[_vm._v("Hora")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Asignada a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-input-group',{},[_c('b-form-input',{attrs:{"type":"time","placeholder":"Ingrese Asignada a","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.incident.crateHour),callback:function ($$v) {_vm.$set(_vm.incident, "crateHour", $$v)},expression:"incident.crateHour"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('span',[_vm._v("Asignada a")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Asignada a"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-input-group',{},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Ingrese Asignada a","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.incident.assigned),callback:function ($$v) {_vm.$set(_vm.incident, "assigned", $$v)},expression:"incident.assigned"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('span',[_vm._v("Estatus de queja ")]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Estatus de queja "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-input-group',{},[_c('b-form-select',{attrs:{"type":"text","placeholder":"Estatus de queja ","state":errors[0] ? false : valid ? true : null,"options":_vm.status},model:{value:(_vm.incident.statusIncident),callback:function ($$v) {_vm.$set(_vm.incident, "statusIncident", $$v)},expression:"incident.statusIncident"}},[_vm._v(" >")]),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('span',[_vm._v("Queja")]),_c('ValidationProvider',{attrs:{"rules":"max:1500","name":"Resumen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota Interna( max: 1500)"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"rows":"2","max-rows":"3","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.incident.summary),callback:function ($$v) {_vm.$set(_vm.incident, "summary", $$v)},expression:"incident.summary"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1),_c('span',{staticClass:"text-muted"},[_vm._v("Carácteres restantes "+_vm._s(_vm.maxTextTarea - _vm.incident.summary.length)+" ")])],1)}}],null,true)})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('span',[_vm._v("Como se Resolvió")]),_c('ValidationProvider',{attrs:{"rules":"max:1500","name":"Descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota Interna( max: 1500)"}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"rows":"2","max-rows":"3","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.incident.description),callback:function ($$v) {_vm.$set(_vm.incident, "description", $$v)},expression:"incident.description"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1),_c('span',{staticClass:"text-muted"},[_vm._v("Carácteres restantes "+_vm._s(_vm.maxTextTarea - _vm.incident.description.length)+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Evidencias"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Evidencias"}},[_c('b-form-file',{attrs:{"placeholder":"Adjunte una imagen como evidencia...","drop-placeholder":"Drop file here...","state":errors[0] ? false : valid ? true : null,"browse-text":"Buscar imagen"},on:{"input":function($event){return _vm.setImgFile(_vm.file)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)}}],null,true)}),_c('b-card-text',{staticClass:"my-1"},[(_vm.incident.file.name != '' && _vm.incident.file != '')?_c('table',{staticClass:"table table-bordered table-sm mb-1"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Nombre")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Borrar")])])]),_c('tbody',[_c('tr',[_c('td',[_c('a',{attrs:{"href":_vm.attrLink + '' + _vm.incident.file.name,"download":"","target":"_blank"}},[_vm._v(_vm._s(_vm.incident.file.nameShort ? _vm.incident.file.nameShort : _vm.incident.file.name))])]),_c('td',[_c('a',{attrs:{"href":_vm.attrLink + '/' + _vm.incident.file.name,"download":"","target":"_blank"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"success"}},[_c('span',{staticClass:"pdfIconLoad"},[_vm._v(" 📄 ")])])],1),_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteFileIncident()}}},[_c('b-icon',{attrs:{"icon":"trash-fill"}})],1)],1)])])]):_vm._e()])],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[(_vm.incident.id != '')?_c('div',{staticClass:"float-left"},[_c('a',{attrs:{"href":_vm.attrLink +
                      '/Transactions/indicentensreport/' +
                     _vm.incident.id+'/',"target":"_blank","rel":"noopener noreferrer"}},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid}},[_c('b-icon-file-earmark-ruled-fill'),_vm._v(" Generear PDF ")],1)],1)]):_vm._e(),(_vm.incident.id == '')?_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.sendIncident(_vm.incident, _vm.selectedOrderOperation.id)}}},[_c('b-icon-check-square-fill'),_vm._v(" Guardar ")],1)],1):_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.update(_vm.incident, _vm.incident.id)}}},[_c('b-icon-check-square-fill'),_vm._v(" Actualizar ")],1)],1)])],1)]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('h3',{staticClass:"text-center mt-1"},[_vm._v("Segimiento de incidencias")]),_c('ValidationObserver',{ref:"incidencias"},[_c('div',{staticClass:"description"},_vm._l((_vm.summary),function(items,index){return _c('div',{key:index,staticClass:"message-box-holder"},[_c('div',{class:items.class},[_c('span',{staticClass:"titleDescription"},[_vm._v(" "+_vm._s(items.username)+" "+_vm._s(items.createdate)+" ")]),_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(items.summary))]),(items.files.name != '')?_c('span',{staticClass:"titleDescriptionFooter align-items-left"},[_c('a',{attrs:{"href":_vm.attrLink + '/' + items.files.name,"download":"","target":"_blank"}},[_c('span',{staticClass:"pdfIcon"},[_vm._v(" 📄 ")])])]):_vm._e(),_c('span',{staticClass:"titleDescriptionFooter align-items-right"},[_vm._v(" "+_vm._s(items.time)+" ")])])])}),0),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('span',[_vm._v("Comentarios")]),_c('ValidationProvider',{attrs:{"rules":"max:255","name":"comments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var valid = ref.valid;
                     var errors = ref.errors;
return _c('b-form-group',{},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"rows":"2","max-rows":"3","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)],1)}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('ValidationProvider',{attrs:{"name":"CommentFile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var valid = ref.valid;
                     var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Evidencias"}},[_c('b-form-file',{attrs:{"placeholder":"Adjunte una imagen como evidencia...","drop-placeholder":"Drop file here...","state":errors[0] ? false : valid ? true : null,"browse-text":"Buscar imagen"},on:{"input":function($event){return _vm.setCommentFile(_vm.commentsFile)}},model:{value:(_vm.commentsFile),callback:function ($$v) {_vm.commentsFile=$$v},expression:"commentsFile"}})],1)}}])}),_c('b-card-text',{staticClass:"my-1"},[(_vm.commentsFile != null)?_c('table',{staticClass:"table table-bordered table-sm mb-1"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Nombre")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Borrar")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.commentsFile.name))]),_c('td',[_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteFileComment()}}},[_c('b-icon',{attrs:{"icon":"trash-fill"}})],1)],1)])])]):_vm._e()])],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('div',{staticClass:"float-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.incident.id == ''},on:{"click":function($event){return _vm.createSummary(_vm.selectedOrderOperation.id)}}},[_c('b-icon-check-square-fill'),_vm._v(" Guardar ")],1)],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }